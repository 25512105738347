import BaseBean from "@/utils/BaseBean";
import {nextTick} from "vue";
import Theme from "@/mythemes/theme";
export interface IPageAnimation{
    selItems:Array<string>//已启用的动画（在onMounted中会重新判断赋值）
    items:Array<TIdValue>
}
export interface IRouteAnimation {
    defRouteAnimation:string
    options:Array<TValueLabel>
}
export interface IDialogAnimation {
    isActiveBg:boolean//是否启用弹出框背景图片（在onMounted中会重新判断赋值）
    backPicPath:string
    imageSource:Array<ResourceFileBean>//手动设置弹出框背景图片
    isActive:boolean//是否启用弹出框动画
}
export interface IAccessedModule{
    isVisible:boolean
    moduleArr:Array<any>
}
export interface ITopDataObj {
    utilInst: TopUtil
    refMap:Map<string,any>
    systemTitle:string
    searchContent:string,//搜索菜单的关键字
    isSearch:boolean,//显示搜索图表还是显示搜索框
    userInfo:IStoreUserInfo
    userMenus:any
    hasMessageReceivePermission:boolean //是否具有接收消息的权限
    hasChangePersonalInfosPermission:boolean //是否具有修改个人信息的权限
    isTopMenu:boolean//是否显示顶部菜单（在onMounted中会重新判断赋值）
    activeTopMenu: string//当前顶部选中的菜单
    topMenu: any//顶部菜单集合（其实就是所有的第一级菜单）
    notQsNum: string//没有签收的消息数量
    pageAnimation:IPageAnimation//自定义动画配置
    routeAnimation:IRouteAnimation//路由动画（默认el-zoom-in-center）
    dialogAnimation:IDialogAnimation//弹出框背景设置
    themes: any//主页风格设置
    avatarImage: string//用户头像图片路径
    logoPng:string//logo图片的路径
    accessedModule:IAccessedModule//已访问模块
    flag1:boolean
    otherParams:any
}
export const defColor={//默认的顶部和左部颜色
    topDefColor:'#242f42',
    slideDefColor:'#324157'
}
export default class TopUtil extends BaseBean{
    public dataObj:ITopDataObj;
    constructor(proxy:any,dataObj:ITopDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //------------------进入top页面，初始化相关项
    //如果是顶部菜单模式，则初始化顶部菜单
    public initTopMenu(mainComp:any):void{
        //从localStorage中取出是否需要显示顶部菜单,恶心的是localStorage取出来是字符串，需要转一下boolean
        this.dataObj.isTopMenu = (localStorage.getItem('isTopMenu')=='true'?true:false);
        if (this.dataObj.isTopMenu)this.dataObj.utilInst.buildTopMenu(mainComp);//展示顶部菜单
    }
    //从本地设置中拿出设置，构建自定义动画已勾选项（如果从未设置过，则默认勾选启用）
    public initPageAnimationConfig():void{
        this.dataObj.pageAnimation.items.forEach((item:any)=>{
            if(this.utils.Tools.isAnimationOpen(item.id))this.dataObj.pageAnimation.selItems.push(item.id);
        })
    }
    //初始化弹出框背景图设置
    public dialogBgConfig():void{
        nextTick(()=>{
            //从sessionStorage取出图片集合
            let imageSourceStr:string=sessionStorage.getItem("imageSource") as string;
            let imageSource:Array<ResourceFileBean>= JSON.parse(this.utils.UtilPub.decrypt(imageSourceStr)) as Array<ResourceFileBean>;
            if(imageSource.length>0){
                this.dataObj.dialogAnimation.imageSource=imageSource;
                //给图片数组添加一项：随机图片，放在图片数组第一项
                let dynamicImage:ResourceFileBean={id: "", saveType: 0, type: 0, uploadTime: "", userId: "", preName:'随机图片',nowName: "",path:'_dynamic_'}
                this.dataObj.dialogAnimation.imageSource.splice(0, 0, dynamicImage);
            }
        })
    }
    //--------------------------------------查询未签收消息数量
    public async queryNotQsMsgNum():Promise<void>{
        nextTick(async ()=>{
            let res = await this.utils.Api.queryNotQsMsgNum();
            //只有为空才会把badge消失掉（为0的时候就不显示数量）
            if (res.notQsNum == 0) this.dataObj.notQsNum = '';
            else this.dataObj.notQsNum = res.notQsNum;
        })
    }
    //--------------------------------------顶部菜单事件
    //点击顶部菜单，更改左边菜单（在左边显示点击菜单的子菜单，然后点击左边的子菜单，在右侧路由显示对应内容）
    public topMenuClickHandler(key:any, keyPath:Array<any>,that:TopUtil,mainComp:any):void{
        //根据顶部菜单id找到该菜单的子菜单项，放到左边显示
        let menu = that.dataObj.userMenus.find((item:any)=> {
            return item.id == key
        });
        if (menu.children && mainComp.slideBarRef)mainComp.slideBarRef.changeMenu(menu.children);
    }
    //--------------------------------------搜索菜单
    //点击搜索图表事件，展示搜索输入框，影藏搜索图标
    public searchHandler():void{
        this.dataObj.isSearch=true;
        nextTick(async () => {
            this.dataObj.refMap.get('searchKeyRef').focus();
        });
    }
    //输入框失去焦点事件，影藏搜索输入框，展示搜索图标
    public searchBlurHandler():void{
        if(this.dataObj.searchContent){
            setTimeout(()=>{//如果有输入内容，则延迟影藏输入框，让handleSelect有时间获取值，否则handleSelect获取不到任何值
                if(this.dataObj.searchContent){
                    this.dataObj.isSearch=false;
                    this.dataObj.searchContent="";
                }
            },300);
        }else{//没有输入搜索内容的时候，直接影藏，不需要让handleSelect获取值
            this.dataObj.isSearch=false;
        }
    }
    //输入查询内容，过滤菜单，为autocomplete赋值
    public querySearchHandler(queryString:string, cb:Function,that:TopUtil):void{
        let queryResult = queryString ? that.filterMenuItem(that.dataObj.userMenus,queryString,undefined) : that.dataObj.userMenus;
        let filterResult:Array<any>=[];
        queryResult.forEach((item:any)=> {
            filterResult.push({value: item.title, path: item.path})
        });
        cb(filterResult);// 调用 callback 返回建议列表的数据
    }
    //根据输入的内容过滤菜单
    public filterMenuItem(userMenus:any,queryString:string,parentTitle:string|undefined):Array<any>{
        let filterResult:Array<any>=[];
        userMenus.forEach((item:any)=> {
            if(item.children){//递归查找孩子节点
                let preTitle=parentTitle?(parentTitle+item.meta.title+" > "):item.meta.title+" > ";
                let tempResult=this.filterMenuItem(item.children,queryString,preTitle);
                if(tempResult.length>0) filterResult=filterResult.concat(tempResult);
            }else{
                if(item.meta.title.toLowerCase().indexOf(queryString.toLowerCase())>-1){
                    let title=parentTitle?(parentTitle+item.meta.title):item.meta.title;
                    filterResult.push({path: item.path, title:title})
                }
            }
        });
        return filterResult;
    }
    //搜索之后，根据选择的菜单进行跳转
    public selectMenuHandler(item:any,that:TopUtil):void{
        if(item.path){
            that.proxy.$router.push({path: item.path});
        }
    }
    //构建顶部菜单
    public buildTopMenu(mainComp:any):void{
        this.dataObj.topMenu = [];
        //得到登录放到sessionStore里面的所有用户菜单，取出来构造出顶部菜单
        this.dataObj.userMenus.forEach((item:any)=> {
            this.dataObj.topMenu.push({
                id: item.id,
                children: item.children,
                path: item.path,
                page: item.page,
                meta:item.meta
            })
        });
        //默认选中第一个顶部菜单
        if (this.dataObj.topMenu.length > 0) {
            this.dataObj.activeTopMenu = this.dataObj.topMenu[0].id;
            //如果第一个顶部菜单有子菜单，则把子菜单设置为左边菜单显示项
            if (this.dataObj.topMenu[0].children && mainComp.slideBarRef){
                mainComp.slideBarRef.changeMenu(this.dataObj.topMenu[0].children);
            }else if(mainComp.slideBarRef){//如果第一个顶部菜单没有子菜单，则左部菜单什么都不显示
                mainComp.slideBarRef.changeMenu([]);//说明是外部连接
            }
        }
    }
    //--------------------------------------个人头像下拉各项事件
    public async personalCommandHandler(command:string,that:TopUtil,mainComp:any):Promise<void>{
        switch (command) {
            case "live2dConfig"://看板娘设置
                mainComp.otherParams.live2d.configVisible=true;
                break;
            case "rightSideBar"://切换侧边栏
                mainComp.otherParams.showTop=false;
                localStorage.setItem('showTop','false');
                this.utils.Const.topHeight=0;
                break;
            case "menuSwitch"://菜单布局切换
                that.utils.Tools.configBox({
                    message: that.proxy.$t('top.menuSwitchTip'),
                    sureFn: async () => {
                        if (that.dataObj.isTopMenu && mainComp.slideBarRef) {//如果当前要展示顶部菜单，切换之后就是不要顶部菜单了，那么就得把所有菜单找出来放到左边显示
                            mainComp.slideBarRef.changeMenu(that.dataObj.userMenus);
                        } else {//如果当前不是显示顶部菜单，那么点击切换的时候，就是要显示的意思了，就得构造顶部菜单了
                            that.buildTopMenu(mainComp);
                        }
                        that.dataObj.isTopMenu = !that.dataObj.isTopMenu;
                        localStorage.setItem('isTopMenu',that.dataObj.isTopMenu+'');
                    }
                });
                break;
            case "animationConfig"://个性化设置
                that.dataObj.otherParams.isVisible=true;
                break;
            case "changePersonalInfos"://修改个人信息
                await that.proxy.$router.replace({path: "/changePersonalInfos"});
                break;
            case "logOut"://退出登录
                mainComp.logOut();
                break;
        }
    }


    //--------------------------------------动画配置
    //页面动画设置改变事件,selItems:当前checkboxGroup中选中的数组集合
    public pageAnimationCkChange(selItems:Array<string>,that:TopUtil):void{
        that.dataObj.pageAnimation.selItems=selItems;//把最新选中的赋值给选selItems
        //循环所有动画设置项，配置是否启用到localStorage
        that.dataObj.pageAnimation.items.forEach((item:TIdValue)=>{
            if (selItems.includes(item.id)) {
                localStorage.setItem(item.id, 'true');
            }else{
                localStorage.setItem(item.id, 'false');
            }
        });
    }
    //路由动画设置改变事件（下拉select改变事件）
    public routeAnimationSelChange(selItem:string):void{
        localStorage.setItem('routeAnimation', selItem);
    }
    //主题设置改变事件（下拉select改变事件）
    public themeSelChange(selItem:string):void{
        localStorage.setItem('curTheme', selItem);
        Theme.switchTheme(selItem);
    }
    //是否启用弹出框背景图片switch开关改变事件
    public dialogBgIsOpen(val:boolean):void{
        localStorage.setItem('dialogBgAnimation',val.toString());
    }
    //是否启用弹出框内容动画switch开关改变事件
    public dialogAnimationIsOpen(val:boolean):void{
        localStorage.setItem('dialogAnimation',val.toString());
    }
    //弹出框背景图片下拉框改变事件
    public dialogBgPicConfigChange(selItem:string,that:TopUtil):void{
        selItem=that.utils.UtilPub.encrypt(selItem)
        localStorage.setItem('backPicPath', selItem);
    }
    //--------------------------------------模块切换
    public switchModuleHandler():void{
        this.dataObj.accessedModule.isVisible=true;
    }
}