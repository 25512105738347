//项目中使用的css颜色变量最好放到这里，因为做主题切换的时候就可以做到适配了
export default class ProjectTheme {
    constructor() {}
    public static allThemes():any{
        return{
            defaultTheme:{
                '--el-color-self-params-bg': '#ecf0f1',
                '--el-color-self-params-second-bg': 'white',
                '--el-color-self-brand-bg': '#ecf0f1',
                '--el-color-self-jrfagsx-bg': '#ecf0f1',
            },
            darkTheme:{
                '--el-color-self-params-bg': '#012447',
                '--el-color-self-params-second-bg': '#1B3651',
                '--el-color-self-brand-bg': '#1B3651',
                '--el-color-self-jrfagsx-bg': '#012447',
            },
            lightBlueTheme:{

            }
        }
    }
}