/**
 * 更改主题。
 * 使用方式：1、外界引入 import Theme from "@/mythemes/theme";
 *         2、Theme.switchTheme(themeName); 调用该方法，传入主题名称即可
 *
 * 目前有两套主题，浅色--defaultTheme，也是系统默认的主题；深色--darkTheme，自定义的主题；
 * 如果今后想要新的主题，可以再复制一份，更改颜色即可。
 * 其实可以拓展开一点，用颜色拾取器组件获取颜色之后，动态的为这些变量赋值，当然可以搞成随机颜色，这样就可以实现在线切换主题了。
 * 还有一种写法就是再App.vue里面的onMounted方法里面加上：
 * document.body.style.setProperty('--el-color-primary', '#56AB2F');
 * document.body.style.setProperty('--el-color-primary-light-9', '#F5FBF0');
 * document.body.style.setProperty('--el-color-primary-light-3', '#95d475');
 * 改变这些变量，也可以实现不同主题，当然只改变了几个变量，应该改变完才有效果。
 *
 * 在系统当中，我们如果写css的时候，自己指定了颜色，动态改变主题的时候是不会有任何变化的，因为写死颜色了。
 * 如果想要动态跟随主题变化，需要在每套主题中定义好变量，注意：变量开头也必须叫做--el-color-，比如
 * --el-color-usermyspanback='#000000',有几套主题就定义几份，然后在使用的地方用如下方式引用
 * background-color: var(--el-color-usermyspanback);
 * */
import ThemeHelper from "@/mythemes/themeHelper";
import ProjectTheme from "@/mythemes/projectTheme";
export default class Theme{
    constructor(){}
    public static allThemes():any{
        return{
            defaultTheme: {
                'color-scheme': 'light',
                '--el-color-white': '#ffffff',
                '--el-color-black': '#000000',
                '--el-color-primary': '#409eff',
                '--el-color-primary-light-3': '#79bbff',
                '--el-color-primary-light-5': '#a0cfff',
                '--el-color-primary-light-7': '#c6e2ff',
                '--el-color-primary-light-8': '#d9ecff',
                '--el-color-primary-light-9': '#ecf5ff',
                '--el-color-primary-dark-2': '#337ecc',
                '--el-color-success': '#67c23a',
                '--el-color-success-light-3': '#95d475',
                '--el-color-success-light-5': '#b3e19d',
                '--el-color-success-light-7': '#d1edc4',
                '--el-color-success-light-8': '#e1f3d8',
                '--el-color-success-light-9': '#f0f9eb',
                '--el-color-success-dark-2': '#529b2e',
                '--el-color-warning': '#e6a23c',
                '--el-color-warning-light-3': '#eebe77',
                '--el-color-warning-light-5': '#f3d19e',
                '--el-color-warning-light-7': '#f8e3c5',
                '--el-color-warning-light-8': '#faecd8',
                '--el-color-warning-light-9': '#fdf6ec',
                '--el-color-warning-dark-2': '#b88230',
                '--el-color-danger': '#f56c6c',
                '--el-color-danger-light-3': '#f89898',
                '--el-color-danger-light-5': '#fab6b6',
                '--el-color-danger-light-7': '#fcd3d3',
                '--el-color-danger-light-8': '#fde2e2',
                '--el-color-danger-light-9': '#fef0f0',
                '--el-color-danger-dark-2': '#c45656',
                '--el-color-error': '#f56c6c',
                '--el-color-error-light-3': '#f89898',
                '--el-color-error-light-5': '#fab6b6',
                '--el-color-error-light-7': '#fcd3d3',
                '--el-color-error-light-8': '#fde2e2',
                '--el-color-error-light-9': '#fef0f0',
                '--el-color-error-dark-2': '#c45656',
                '--el-color-info': '#909399',
                '--el-color-info-light-3': '#b1b3b8',
                '--el-color-info-light-5': '#c8c9cc',
                '--el-color-info-light-7': '#dedfe0',
                '--el-color-info-light-8': '#e9e9eb',
                '--el-color-info-light-9': '#f4f4f5',
                '--el-color-info-dark-2': '#73767a',
                '--el-bg-color': '#ffffff',
                '--el-bg-color-page': '#f2f3f5',
                '--el-bg-color-overlay': '#ffffff',
                '--el-text-color-primary': '#303133',
                '--el-text-color-regular': '#606266',
                '--el-text-color-secondary': '#909399',
                '--el-text-color-placeholder': '#a8abb2',
                '--el-text-color-disabled': '#c0c4cc',
                '--el-border-color': '#dcdfe6',
                '--el-border-color-light': '#e4e7ed',
                '--el-border-color-lighter': '#ebeef5',
                '--el-border-color-extra-light': '#f2f6fc',
                '--el-border-color-dark': '#d4d7de',
                '--el-border-color-darker': '#cdd0d6',
                '--el-fill-color': '#f0f2f5',
                '--el-fill-color-light': '#f5f7fa',
                '--el-fill-color-lighter': '#fafafa',
                '--el-fill-color-extra-light': '#fafcff',
                '--el-fill-color-dark': '#ebedf0',
                '--el-fill-color-darker': '#e6e8eb',
                '--el-fill-color-blank': '#ffffff',
                '--el-box-shadow': '0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08)',
                '--el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, .12)',
                '--el-box-shadow-lighter': '0px 0px 6px rgba(0, 0, 0, .12)',
                '--el-box-shadow-dark': '0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16)',
                '--el-disabled-bg-color': 'var(--el-fill-color-light)',
                '--el-disabled-text-color': 'var(--el-text-color-placeholder)',
                '--el-disabled-border-color': 'var(--el-border-color-light)',
                '--el-overlay-color': 'rgba(0, 0, 0, .8)',
                '--el-overlay-color-light': 'rgba(0, 0, 0, .7)',
                '--el-overlay-color-lighter': 'rgba(0, 0, 0, .5)',
                '--el-mask-color': 'rgba(255, 255, 255, .9)',
                '--el-mask-color-extra-light': 'rgba(255, 255, 255, .3)',
                '--el-border-width': '1px',
                '--el-border-style': 'solid',
                '--el-border-color-hover': 'var(--el-text-color-disabled)',
                '--el-border': 'var(--el-border-width) var(--el-border-style) var(--el-border-color)',
                '--el-svg-monochrome-grey': 'var(--el-border-color)',
                //--------------------------------------自定义变量
                '--el-color-self-tb-tool-lrt-border': '#EBEEF5',
                '--el-color-self-tb-title-bg-began': '#ecf0f1',
                '--el-color-self-tb-title-bg-end': '#FAFAFA',
                '--el-color-self-tb-title-lr-border': '#EBEEF5',
                '--el-color-self-tb-sel-row-bg': '#ecf0f1',
                '--el-color-self-collapse-lr-border': '#EBEEF5',
                '--el-color-self-collapse-bg': '#FAFAFA',
                '--el-color-self-tab-b-border': '#dfdfdf',
                '--el-color-self-top-color': '#fff',
                '--el-color-self-top-bg': '#242f42',
                '--el-color-self-slide-bg': '#324157',
                // '--el-color-self-slide-bg': '#012447',
                '--el-color-self-portal-border': 'darkgray'
            },
            darkTheme:{
                'color-scheme': 'dark',
                '--el-color-white': '#000000', // 基础浅色
                '--el-color-black': 'rgb(31,31,31)', // 基础深色
                '--el-color-primary': '#409eff',
                '--el-color-primary-light-3': '#3375b9',
                '--el-color-primary-light-5': '#2a598a',
                '--el-color-primary-light-7': '#213d5b',
                '--el-color-primary-light-8': '#1d3043',
                '--el-color-primary-light-9': '#18222c',
                '--el-color-primary-dark-2': '#66b1ff',
                '--el-color-success': '#67c23a',
                '--el-color-success-light-3': '#4e8e2f',
                '--el-color-success-light-5': '#3e6b27',
                '--el-color-success-light-7': '#2d481f',
                '--el-color-success-light-8': '#25371c',
                '--el-color-success-light-9': '#1c2518',
                '--el-color-success-dark-2': '#85ce61',
                '--el-color-warning': '#e6a23c',
                '--el-color-warning-light-3': '#a77730',
                '--el-color-warning-light-5': '#7d5b28',
                '--el-color-warning-light-7': '#533f20',
                '--el-color-warning-light-8': '#3e301c',
                '--el-color-warning-light-9': '#292218',
                '--el-color-warning-dark-2': '#ebb563',
                '--el-color-danger': '#f56c6c',
                '--el-color-danger-light-3': '#b25252',
                '--el-color-danger-light-5': '#854040',
                '--el-color-danger-light-7': '#582e2e',
                '--el-color-danger-light-8': '#412626',
                '--el-color-danger-light-9': '#2b1d1d',
                '--el-color-danger-dark-2': '#f78989',
                '--el-color-error': '#f56c6c',
                '--el-color-error-light-3': '#b25252',
                '--el-color-error-light-5': '#854040',
                '--el-color-error-light-7': '#582e2e',
                '--el-color-error-light-8': '#412626',
                '--el-color-error-light-9': '#2b1d1d',
                '--el-color-error-dark-2': '#f78989',
                '--el-color-info': '#909399',
                '--el-color-info-light-3': '#6b6d71',
                '--el-color-info-light-5': '#525457',
                '--el-color-info-light-7': '#393a3c',
                '--el-color-info-light-8': '#2d2d2f',
                '--el-color-info-light-9': '#202121',
                '--el-color-info-dark-2': '#a6a9ad',
                /* --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.36),0px 8px 20px rgba(0, 0, 0, 0.72); */
                '--el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, 0.72)',
                '--el-box-shadow-lighter': '0px 0px 6px rgba(0, 0, 0, 0.72)',
                '--el-box-shadow-dark': '0px 16px 48px 16px rgba(0, 0, 0, 0.72),0px 12px 32px #000000,0px 8px 16px -8px #000000',
                '--el-bg-color-page': '#0a0a0a',
                '--el-bg-color': '#141414',
                '--el-bg-color-overlay': '#1d1e1f',
                '--el-text-color-primary': '#E5EAF3',
                '--el-text-color-regular': '#CFD3DC',
                '--el-text-color-secondary': '#A3A6AD',
                '--el-text-color-placeholder': '#8D9095',
                '--el-text-color-disabled': '#6C6E72',
                '--el-border-color-darker': '#636466',
                '--el-border-color-dark': '#58585B',
                '--el-border-color': '#4C4D4F',
                '--el-border-color-light': '#414243',
                '--el-border-color-lighter': '#363637',
                '--el-border-color-extra-light': '#2B2B2C',
                '--el-fill-color-darker': '#424243',
                '--el-fill-color-dark': '#39393A',
                '--el-fill-color': '#303030',
                '--el-fill-color-light': '#262727',
                '--el-fill-color-lighter': '#1D1D1D',
                '--el-fill-color-extra-light': '#191919',
                '--el-fill-color-blank': 'transparent',
                '--el-mask-color': 'rgba(0, 0, 0, 0.8)'  ,
                '--el-mask-color-extra-light': 'rgba(0, 0, 0, 0.3)',

                '--el-color-self-tb-tool-lrt-border': 'var(--el-border-color)',
                '--el-color-self-tb-title-bg-began': 'var(--el-color-white)',
                '--el-color-self-tb-title-bg-end': 'var(--el-color-black)',
                '--el-color-self-tb-title-lr-border': 'var(--el-border-color)',
                '--el-color-self-tb-sel-row-bg': 'var(--el-color-black)',
                '--el-color-self-collapse-lr-border': 'var(--el-border-color)',
                '--el-color-self-collapse-bg': 'var(--el-color-white)',
                '--el-color-self-tab-b-border': 'var(--el-border-color)',
                '--el-color-self-top-color': '#fff',
                '--el-color-self-top-bg': 'var(--el-color-black)',
                '--el-color-self-slide-bg': 'var(--el-color-white)',
                '--el-color-self-portal-border': 'darkgray'
            },
            lightBlueTheme: {
                'color-scheme': 'lightBlueTheme',
                '--el-color-white': '#1B3651', // 基础浅色
                '--el-color-black': '#012447', // 基础深色
                '--el-color-primary': '#409eff',
                '--el-color-primary-light-3': '#79bbff',
                '--el-color-primary-light-5': '#a0cfff',
                '--el-color-primary-light-7': '#c6e2ff',
                '--el-color-primary-light-8': '#d9ecff',
                '--el-color-primary-light-9': '#ecf5ff',
                '--el-color-primary-dark-2': '#337ecc',
                '--el-color-success': '#67c23a',
                '--el-color-success-light-3': '#95d475',
                '--el-color-success-light-5': '#b3e19d',
                '--el-color-success-light-7': '#d1edc4',
                '--el-color-success-light-8': '#e1f3d8',
                '--el-color-success-light-9': '#f0f9eb',
                '--el-color-success-dark-2': '#529b2e',
                '--el-color-warning': '#e6a23c',
                '--el-color-warning-light-3': '#eebe77',
                '--el-color-warning-light-5': '#f3d19e',
                '--el-color-warning-light-7': '#f8e3c5',
                '--el-color-warning-light-8': '#faecd8',
                '--el-color-warning-light-9': '#fdf6ec',
                '--el-color-warning-dark-2': '#b88230',
                '--el-color-danger': '#f56c6c',
                '--el-color-danger-light-3': '#f89898',
                '--el-color-danger-light-5': '#fab6b6',
                '--el-color-danger-light-7': '#fcd3d3',
                '--el-color-danger-light-8': '#fde2e2',
                '--el-color-danger-light-9': '#fef0f0',
                '--el-color-danger-dark-2': '#c45656',
                '--el-color-error': '#f56c6c',
                '--el-color-error-light-3': '#f89898',
                '--el-color-error-light-5': '#fab6b6',
                '--el-color-error-light-7': '#fcd3d3',
                '--el-color-error-light-8': '#fde2e2',
                '--el-color-error-light-9': '#fef0f0',
                '--el-color-error-dark-2': '#c45656',
                '--el-color-info': '#909399',
                '--el-color-info-light-3': '#b1b3b8',
                '--el-color-info-light-5': '#c8c9cc',
                '--el-color-info-light-7': '#dedfe0',
                '--el-color-info-light-8': '#e9e9eb',
                '--el-color-info-light-9': '#f4f4f5',
                '--el-color-info-dark-2': '#73767a',
                '--el-bg-color': '#1B3651',
                '--el-bg-color-page': '#1B3651',
                '--el-bg-color-overlay': '#1B3651',
                '--el-text-color-primary': '#FFFFFF', // 主要文字颜色
                '--el-text-color-regular': '#FFFFFF', // 常规文字颜色
                '--el-text-color-placeholder': '#c0c4cc', // 占位文字颜色
                '--el-text-color-secondary': '#FFFFFF', // 次要文字颜色
                '--el-text-color-disabled': '#c0c4cc',
                '--el-border-color': '#388DE0',
                '--el-border-color-light': '#388DE0', // 二级边框颜色
                '--el-border-color-lighter': '#58A9F5', // 三级边框颜色
                '--el-border-color-extra-light': '#86B3F9', // 四级边框颜色
                '--el-border-color-dark': '#d4d7de',
                '--el-border-color-darker': '#cdd0d6',
                '--el-fill-color': '#012447',
                '--el-fill-color-light': '#1B3651',
                '--el-fill-color-lighter': '#1B3651',
                '--el-fill-color-extra-light': '#1B3651',
                '--el-fill-color-dark': '#1B3651',
                '--el-fill-color-darker': '#1B3651',
                '--el-fill-color-blank': '#1B3651',
                '--el-box-shadow': '0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08)',
                '--el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, .12)',
                '--el-box-shadow-lighter': '0px 0px 6px rgba(0, 0, 0, .12)',
                '--el-box-shadow-dark': '0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16)',
                '--el-disabled-bg-color': 'var(--el-fill-color-light)',
                '--el-disabled-text-color': 'var(--el-text-color-placeholder)',
                '--el-disabled-border-color': 'var(--el-border-color-light)',
                '--el-overlay-color': 'rgba(0, 0, 0, .8)',
                '--el-overlay-color-light': 'rgba(0, 0, 0, .7)',
                '--el-overlay-color-lighter': 'rgba(0, 0, 0, .5)',
                '--el-mask-color': 'rgba(255, 255, 255, .9)',
                '--el-mask-color-extra-light': 'rgba(255, 255, 255, .3)',
                '--el-border-width': '1px',
                '--el-border-style': 'solid',
                '--el-border-color-hover': 'var(--el-text-color-disabled)',
                '--el-border': 'var(--el-border-width) var(--el-border-style) var(--el-border-color)',
                '--el-svg-monochrome-grey': 'var(--el-border-color)',
                //--------------------------------------自定义变量
                '--el-color-self-tb-tool-lrt-border': 'var(--el-border-color)',
                '--el-color-self-tb-title-bg-began': 'var(--el-color-white)',
                '--el-color-self-tb-title-bg-end': 'var(--el-color-black)',
                '--el-color-self-tb-title-lr-border': 'var(--el-border-color)',
                '--el-color-self-tb-sel-row-bg': 'var(--el-color-black)',
                '--el-color-self-collapse-lr-border': 'var(--el-border-color)',
                '--el-color-self-collapse-bg': 'var(--el-color-white)',
                '--el-color-self-tab-b-border': 'var(--el-border-color)',
                '--el-color-self-top-color': 'var(--el-text-color-primary)',
                '--el-color-self-top-bg': 'rgb(27,54,89)',
                '--el-color-self-slide-bg': 'var(--el-color-black)',
                '--el-color-self-portal-border': 'var(--el-border-color)'
            }
        };
    }
    public static switchTheme(themeName:string):void{
        //系统的主题变量
        let themeObj= Theme.allThemes();
        //项目的主题变量
        let projectTheme=ProjectTheme.allThemes();
        switch (themeName) {
            case 'darkTheme':
                Object.assign(themeObj.darkTheme,projectTheme.darkTheme);
                break;
            case 'lightBlueTheme':
                Object.assign(themeObj.lightBlueTheme,projectTheme.lightBlueTheme);
                break;
            default:
                //合并项目的主题变量到系统的主题变量中
                Object.assign(themeObj.defaultTheme,projectTheme.defaultTheme);
        }
        // 如果没有传入主题或者传入的主题不存在，则采用默认的主题
        if(!themeName || !themeObj[themeName])themeName='defaultTheme';
        themeObj= themeObj[themeName];
        ThemeHelper.changeTheme(themeObj);
    }
}